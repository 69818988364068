import './bootstrap/index.esm';

const DECORATION_SELECTOR: string = '.decoration';
const SECTION_SELECTOR: string = 'section';
const NAVBAR_SELECTOR: string = '.navbar';
const NAVBAR_COLLAPSE_SELECTOR: string = '.navbar-collapse';
const SECTION_TOGGLE_BUTTONS_SELECTOR: string = 'button[data-toggle=true]';

const NAVBAR_OPENED_CLASS: string = 'navbar--opened';
const SECTION_EXPANDED_CLASS: string = 'expanded';
const TRANSPARENT_BACKGROUND_CLASS: string = 'bg-primary-transparent';

const NAVBAR_GETS_BACKGROUND_OFFSET: number = 20;


const setAutoplayForVideoModal = () => {
    document.getElementById('video').addEventListener('show.bs.modal', event => {
        const target: Element = event.currentTarget as Element;
        const videoModal = target.querySelector("#video-youtube");
        const src = videoModal.getAttribute('data-target-src')
        videoModal.setAttribute('src', src + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0");
    });

    document.getElementById('video').addEventListener('hide.bs.modal', event => {
        const target: Element = event.currentTarget as Element;
        target.querySelector("#video-youtube").setAttribute('src', '');
    });
}


const getAllDecorations = (): HTMLElement[] => {
    return Array.from(
        document.querySelectorAll(DECORATION_SELECTOR) as unknown as HTMLCollectionOf<HTMLElement>
    )
};

const getAllSections = (): HTMLElement[] => {
    return Array.from(
        document.querySelectorAll(SECTION_SELECTOR) as unknown as HTMLCollectionOf<HTMLElement>
    )
}

const setDecorationPosition = (decorationElement: HTMLElement): void => {
    decorationElement.style.top = `${decorationElement.getAttribute('data-top')}px`;
    decorationElement.style.left = `${decorationElement.getAttribute('data-left')}px`;
};

const setPositionsForAllDecorations = (): void => {
    getAllDecorations().forEach(setDecorationPosition);
};

const addNavbarOpenedOnEvent = (navbar: Element) => (): void => {
    navbar.classList.add(NAVBAR_OPENED_CLASS);
};

const removeNavbarOpenedOnEvent = (navbar: Element) => (): void => {
    navbar.classList.remove(NAVBAR_OPENED_CLASS);
}

const addEventsListenersToNavbar = (): void => {
    const navbar: Element = document.querySelector(NAVBAR_SELECTOR);
    const navbarCollapse: Element = document.querySelector(NAVBAR_COLLAPSE_SELECTOR);

    navbarCollapse.addEventListener('show.bs.collapse', addNavbarOpenedOnEvent(navbar));
    navbarCollapse.addEventListener('hidden.bs.collapse', removeNavbarOpenedOnEvent(navbar));
}

const addBackgroundToNavbarOnScroll = (): void => {
    const navbar: Element = document.querySelector(NAVBAR_SELECTOR);

    document.addEventListener('scroll', () => {
        if (scrollY >= NAVBAR_GETS_BACKGROUND_OFFSET) {
            if (!navbar.classList.contains(TRANSPARENT_BACKGROUND_CLASS)) {
                navbar.classList.add(TRANSPARENT_BACKGROUND_CLASS);
            }
        } else {
            navbar.classList.remove(TRANSPARENT_BACKGROUND_CLASS);
        }
    });
};

const addSectionExpandEventsToButtons = (): void => {
    getAllSections().forEach((section) => {
        section.querySelectorAll(SECTION_TOGGLE_BUTTONS_SELECTOR).forEach(
            button => {
                button.addEventListener('click', () => {
                    section.classList.toggle(SECTION_EXPANDED_CLASS);
                });
            }
        );
    });
};

const onDOMContentLoaded = (): void => {
    setPositionsForAllDecorations();
    addBackgroundToNavbarOnScroll();
    addEventsListenersToNavbar();
    addSectionExpandEventsToButtons();
    setAutoplayForVideoModal();
};

document.addEventListener('DOMContentLoaded', onDOMContentLoaded);

